import { render, staticRenderFns } from "./doctorTable.vue?vue&type=template&id=501a7ede&scoped=true&"
import script from "./doctorTable.vue?vue&type=script&lang=js&"
export * from "./doctorTable.vue?vue&type=script&lang=js&"
import style0 from "./doctorTable.vue?vue&type=style&index=0&id=501a7ede&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501a7ede",
  null
  
)

export default component.exports